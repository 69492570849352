<template>
  <div class="container">
    <div class="rewards-banner relative" v-if="!$route.params.section">
      <img src="/assets/rewards-program-masthead.jpg" alt="LBP Rewards">
    </div>
    <div v-else class="lg:mt-5">
      <h1 class="rewards-title uppercase tracking-lg md:leading-sm font-black text-big sm:text-above-h2 py-4 md:pt-7.5 md:pb-10 text-center">
        {{ $t('LBP Rewards') }}
        <span class="block text-2h6 font-serif tracking-tiny normal-case italic md:pt-md font-normal leading-sm">
          {{ $t('The most generous rewards program the industry has ever known.') }}
        </span>
      </h1>
    </div>
    <nav class="w-full">
      <div v-show="!$route.params.section" class="navigation-toggle flex items-center justify-center text-h5 uppercase font-bold leading-h6 border-t border-solid border-grey-6 md:hidden" @click="navigationOpen = !navigationOpen">
        <span>{{ $t('Explore the benefits') }}</span>
        <i class="ss-gizmo ml-1" :class="navigationOpen ? 'ss-navigateup' : 'ss-navigatedown'"></i>
      </div>
      <ul class="navigation flex border-b md:border border-solid border-grey-6 overflow-hidden flex-col md:flex-row" :class="{ 'is-open': !!$route.params.section || navigationOpen }">
        <li v-for="(page, index) in navigation" :key="index" class="md:w-1/5 text-center border-solid border-grey-6 navigation-item border-t md:border-t-0 md:border-r">
          <router-link
            class="text-average tracking-mid uppercase leading-h6 flex justify-center items-center w-full border-b border-solid border-transparent hover:border-dark navigation-link"
            :to="localizedRoute(page.link)"
            @click.native="navigationOpen = false"
          >
            {{ page.title }}
          </router-link>
        </li>
      </ul>
    </nav>
    <div class="px-5 xl:px-0 pt-6 lg:pt-16 pb-10 lg:pb-20">
      <sb-render v-if="story && story.content" :item="story.content" />
    </div>
  </div>
</template>

<script>
import StoryblokMixin from 'src/modules/vsf-storyblok-module/components/StoryblokMixin'

export default {
  name: 'Rewards',
  mixins: [StoryblokMixin],
  data () {
    return {
      navigation: [
        {
          title: this.$t('Earn free products'),
          link: '/rewards-program/free-products'
        },
        {
          title: this.$t('Education benefits'),
          link: '/rewards-program/education-benefits'
        },
        {
          title: this.$t('Share in web profits'),
          link: '/rewards-program/web-profits'
        },
        {
          title: this.$t('Reward levels'),
          link: '/rewards-program/reward-levels'
        },
        {
          title: this.$t('LBP exclusive'),
          link: '/rewards-program/lbp-exclusive'
        }
      ],
      navigationOpen: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .container {
    @apply px-0;

    @screen xl {
      @apply px-5;
    }
  }

  .rewards-banner {
    @screen md {
      margin-bottom: -100px;
      top: -100px;
    }
  }
  .rewards-title {
    mask-image: url('/assets/texture-mask-invert.png');
  }

  .navigation {
    max-height: 0;
    transition: max-height .5s ease;

    &.is-open {
      max-height: 1000px;
    }

    @screen md {
      max-height: none;
    }
  }

  .navigation-toggle {
    height: 60px;
  }

  .navigation-link {
    height: 60px;
    transition: border-bottom-color .25s ease;

    &.router-link-exact-active {
      @apply border-6 border-solid border-dark;
    }
  }
  .navigation-item {
    &:last-child {
      @apply border-r-0;
    }
  }

  ::v-deep {
    .table-blok {
      @apply border border-grey-14 w-full;

      th, td {
        width: 14.285714285714286%;

        &:first-child {
          @apply py-3 px-2 bg-grey-20 text-right text-h6 uppercase font-normal leading-loose tracking-mid border border-grey-14 align-baseline;

          @screen md {
            @apply py-5 pr-md pl-10;
          }
        }

        &:not(:first-child) {
          @apply text-center font-black text-above-md border border-grey-14;
        }
      }

      th:not(:first-child) {
        @apply bg-black py-md font-serif text-white text-above-md italic font-normal;
      }

      td[colspan="6"], td[colspan="7"] {
        @apply relative text-white bg-brown-2 font-black uppercase leading-h3 text-h6 text-center tracking-mid p-0;

        &::after {
          @apply absolute border-solid leading-none border-transparent border-10;
          content: '';
          top: 33px;
          left: 50%;
          margin-left: -10px;
          border-top-color: theme('colors.brown-2');
        }
      }
    }

    .head-item__image {
      @apply block bg-no-repeat mt-sm mx-auto;
      background-image: url(/assets/sprite-rewards-level.png);
      background-position: 0 0;
      height: 80px;
      width: 80px;

      &--gold {
        background-image: url('/assets/sprite-rewards-gold.png');
        background-size: contain;
      }

      &--sapphire {
        background-position: 0 -80px;
      }

      &--emerald {
        background-position: 0 -160px;
      }

      &--ruby {
        background-position: 0 -240px;
      }

      &--diamond {
        background-position: 0 -320px;
      }
    }

    .wysiwyg-container {
      &.title-container {
        @apply p-0 text-center mb-2;

        @screen sm {
          @apply mb-lg text-left;
        }

        h1 {
          @apply uppercase text-above-mid font-bold tracking-mid leading-sm;
        }
      }

      &.intro-text {
        @apply pt-10 px-0 pb-0 mb-20;
        max-width: 820px;

        h2 {
          @apply uppercase tracking-lg font-black text-lg leading-h3 mb-md;
        }

        h3 {
          @apply uppercase text-base leading-h4 tracking-xl;
        }

        h5 {
          @apply uppercase text-average leading-none mb-md tracking-mid font-bold pb-30px mt-0 mb-30px border-b border-solid border-grey-6;
        }

        p {
          @apply mt-0 mb-6;
        }

        img {
          @apply w-full;
        }

        blockquote {
          @apply block pb-4 w-full font-serif relative text-above-md italic mb-4 leading-h5;

          &::after {
            @apply absolute bottom-0 left-0 bg-grey-40;
            content: '';
            height: 1px;
            width: 115px;
          }

          p {
            @apply m-0;
          }

          img {
            @apply mb-sm border border-grey-40 border-solid;
          }
        }

        hr {
          @apply block my-lg border-t-2 border-solid border-black;
        }

        li {
          @apply text-above-md leading-sm;

          p {
            @apply m-0;
          }
        }

        a {
          @apply text-blue;

          &:hover {
            @apply underline text-royalblue;
          }
        }

        .intro__button {
          @apply pl-10 pr-15 border border-grey-light border-solid tracking-sm inline-flex items-center justify-between font-sans bg-black text-white cursor-pointer uppercase relative text-h5 transition-500 transition-ease;

          height: 60px;
          line-height: 60px;

          &:hover {
            @apply bg-brown-2 text-white no-underline;
          }

          .button-arrow-icon {
            right: 20px;
          }
        }
      }

      &.note-text {
        @apply text-average leading-h6 font-normal text-dark p-0 mb-20;
        max-width: none;

        p {
          @apply mt-4 mb-0;
        }
      }

      &.column-content {
        @apply p-0 m-0;
        max-width: 572px;

        h6 {
          @apply uppercase text-average leading-none font-bold mb-md;
        }
      }
    }

    .rewards-aside-image {
      @screen sm {
        position: relative;
        top: -56px;
      }
    }
  }
</style>
